<template>
    <div>
        <h2><router-link :to="{ name: 'po' }">รายการซื้อ</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}รายการซื้อ {{ order.orderNumber }}</h2>
        <v-row class="mt-4">
          <v-col>
            <v-card>
              <v-form v-model="isFormValid">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="order.created_at"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="order.created_at"
                            label="วันที่"
                            :prepend-inner-icon="icons.mdiCalendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                            hide-details="auto"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="order.created_at"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn                            
                            color="primary"
                            @click="$refs.menu.save(order.created_at)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>                     
                    </v-col>                    
                  </v-row>       
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>           
                  <v-row>
                    <v-col cols="12" md="6">
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.supplier"
                        label="ผู้จำหน่าย / ซัพพลายเออร์"
                        placeholder="พิมพ์เพื่อค้นหาซัพพลายเออร์ (เว้นว่างหากไม่เลือก)"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/procure/po/supplierList'"
                        dense
                        return-object                        
                        item-text="name"
                        item-value="id"
                        :disabled="!order.isSupplierChangable || $store.getters['app/GET_APP_MODE'] == 'read'"
                        hide-details="auto"
                        @change="selectSupplier"
                      ></delay-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.taxno"
                        label="เลขประจำตัวผู้เสียภาษี"                    
                        no-data-text="ไม่พบข้อมูล"                        
                        dense                                                                                                                        
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.address"
                        label="ที่อยู่"
                        dense
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row> 
                  <v-row>
                    <v-col cols="12" md="6">
                      <delay-autocomplete
                        outlined
                        hide-selected
                        cache-items
                        v-model="order.warehouse"
                        label="คลัง/สาขา ที่ซื้อเข้า"
                        placeholder="พิมพ์เพื่อค้นหาคลัง (เว้นว่างหากไม่เลือก)"
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/procure/po/warehouseList'"
                        dense
                        return-object
                        ref="salechannelAutocomplete"                        
                        item-text="name"
                        item-value="id"
                        :disabled="!order.isWarehouseChangeable || $store.getters['app/GET_APP_MODE'] == 'read'"
                      ></delay-autocomplete>
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col>
                      <p-o-product-input
                      v-model="order"
                      :url="'/procure/po'"
                      :warehouse_id="!!order.warehouse ? order.warehouseId : null"
                      :disabled="!order.isItemEditable || $store.getters['app/GET_APP_MODE'] == 'read'"
                      ></p-o-product-input>
                    </v-col>              
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model.number="order.misc_cost"
                        label="ค่าใช้จ่ายอื่น ๆ ที่อยู่นอกระบบภาษี"                                            
                        dense                                                                                                                        
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-textarea
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.note"
                        label="หมายเหตุ"
                        dense
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-card-actions>
                <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid || order.orderItems.length == 0"
                :loading="sendingData"
                @click="submitForm(false)"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read' && order.status != 'cancelled'"
                >
                {{ $store.getters['app/GET_APP_MODE'] == 'create' || order.status == 'draft' ? "สร้าง" : "แก้ไข"}}
                </v-btn>

                <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.push({ name: 'po' })"
                >
                กลับ
                </v-btn>

                <v-btn
                color="secondary"               
                text                 
                large
                :loading="sendingData"
                @click="submitForm(true)"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read' && order.status == 'draft'"
                >
                บันทึกแบบร่าง
                </v-btn>                
                
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import { BuyOrder } from '@/classes/order/BuyOrder'
import { BuyOrderItem } from '@/classes/order/BuyOrderItem'
import DelayCombobox from '@/components/DelayCombobox.vue'
import LightBox from '@/components/LightBox.vue'
import POProductInput from '@/components/order/POProductInput.vue'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { dateObjectToDBString } from '@/helpers/dateTimeConverter'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
import { mdiCalendar } from '@mdi/js'
export default {
  data() {
    return {
      orderNumber: null,
      isFormValid: false,
      sendingData: false,
      order: null,
      salechannel: null,
      warehouse: null,
      currentStatus: null,
      menu: false,
      changeStatusDialog: {
        isShow: false,
        title: '',
        body: '',
      },
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
      icons: {
        mdiCalendar,
      },
    }
  },
  async created() {
    const po_id = this.$route.params.po_id

    if (po_id == 'create') {
      const date = new Date()
      this.order = new BuyOrder({
        created_at: dateObjectToDBString(date),
      })
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'OnlineOrder'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/procure/po/' + po_id)

      this.convertDataFromServerToForm(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
    this.currentStatus = this.order.status
  },
  methods: {
    async submitForm(is_draft = false) {
      this.sendingData = true
      try {
        const sendData = {
          ...this.order.serialize(),
          is_draft: is_draft,
        }

        const po_id = this.$route.params.po_id
        if (po_id == 'create') {
          const retResult = await asyncPostAsJson('/procure/po', sendData)

          this.$router.push({
            name: 'po',
          })
        } else {
          sendData['_method'] = 'PUT'
          await asyncPostAsJson('/procure/po/' + po_id, sendData)

          this.$router.push({ name: 'po' })
        }
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    convertDataFromServerToForm(orderDataFromServer) {
      this.order = new BuyOrder({
        ...orderDataFromServer,
        created_at: dateObjectToDBString(new Date(orderDataFromServer.created_at)),
      })

      const { products } = orderDataFromServer

      // this.warehouse = warehouse

      const order = this.order

      products.forEach(element => {
        const orderItem = new BuyOrderItem({
          id: element.id,
          images: element.images,
          name: element.name,
          sku: element.sku,
          barCode: element.barCode,
          note: element.pivot.note,
          quantity: element.pivot.quantity,
          price: element.pivot.price,
          discount: element.pivot.discount,
          discount_type: element.pivot.discount_type,
          vat: element.pivot.vat,
          unit: element.unit,
        })

        order.addOrderItem(orderItem)
      })
    },
    selectSupplier({ taxno, address }) {
      this.order.taxno = taxno
      this.order.address = address
    },
  },
  components: {
    DelayCombobox,
    LightBox,
    POProductInput,
  },
}
</script>,